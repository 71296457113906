<script>
	import SavingIcon from '$lib/icons/SavingIcon.svelte';
	import NotFoundPage from './core/NotFoundPage.svelte';
	import SaveBeforeExit from './core/SaveBeforeExit.svelte';

	export let isLoading = false;
	export let isNotFound = false;
	export let noPadding = false;
</script>

<div class="content-body">
	{#if isNotFound}
		<NotFoundPage />
	{:else if isLoading}
		<div class="loading">
			<SavingIcon />
		</div>
	{:else}
		<slot />

		{#if !noPadding}
			<div style="height: 100px;" />
		{/if}
	{/if}
</div>

<style>
	.loading {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 100px;
	}
</style>
